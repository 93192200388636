import {Box, CardMedia, Paper} from '@mui/material';
import {Outlet} from 'react-router-dom';
import logo from '@/assets/sbi1.png';

const Layout = () : JSX.Element => {
    return (
        <>
            <main>
                <Box
                    sx={{
                        minHeight: '50vh',
                    }}
                >
                    <Outlet/>
                </Box>
            </main>
            <Paper sx={{
                marginTop: '60px',
                width: '100%',
                bottom: 0,
                bgcolor: 'text.primary',
                color: 'background.paper',
            }} component="footer" square variant="outlined">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <CardMedia
                        sx={{width: 'auto'}}
                        component="img"
                        image={logo}
                        alt="SBI Fine Fabric Finishing"
                    />
                    <Box
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        © Copyright 2023. SBI Fine Fabric Finishing. All Rights Reserved.
                    </Box>
                </Box>
            </Paper>
        </>
    );
};

export default Layout;
