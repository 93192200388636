import {Link} from '@mui/material';

const Terms = () : JSX.Element => {
    return (<>
        Please read the{' '}
        <Link href="https://sbifinishing.com/terms-conditions/" target="_blank">
            Terms &amp; Conditions
        </Link>
        .
    </>);
};

export default Terms;
