import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import {Alert, Box, Card} from '@mui/material';
import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {z} from 'zod';
import Terms from '@/components/Terms';
import {apiUrl} from '@/utils/api';

const ApiResponseSchema = z.object({
    success: z.boolean(),
    message: z.string(),
});

type ApiResponseType = z.infer<typeof ApiResponseSchema>;

const Home = () : JSX.Element => {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [sentResponse, setSentResponse] = useState<ApiResponseType | undefined>(undefined);

    const acceptTerms = async () => {
        try {
            setSentResponse(undefined);
            setLoading(true);
            const response = await fetch(apiUrl('/accept/terms/save').toString(), {
                method: 'POST',
                body: JSON.stringify({ID: searchParams.get('ID')}),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setLoading(false);

            try {
                const scriptResult = ApiResponseSchema.parse(await response.json());
                setSentResponse(scriptResult);
                setLoading(false);
                return;
            } catch (e) {
                setSentResponse({
                    success: false,
                    message: 'There was an error saving your response.',
                });
            }
        } catch (e) {
            setLoading(false);
            setSentResponse({
                success: false,
                message: 'There was an error getting the response from the api.',
            });
        }

        setLoading(false);
    };

    return (
        <>
            <Box
                sx={{
                    mx: 2,
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {!sentResponse?.success && <Card
                    sx={{
                        p: 3,
                        borderRadius: 2,
                    }}
                >
                    <Terms/>
                    <Box sx={{mt: 2}}>
                        Click the button below if you accept SBI Fine Fabric Finishing terms and conditions.
                    </Box>
                    <LoadingButton
                        sx={{m: 2}}
                        loading={loading}
                        loadingPosition="end"
                        endIcon={<SendIcon/>}
                        variant="outlined"
                        onClick={() => {
                            void acceptTerms();
                        }}>
                        Accept
                    </LoadingButton>
                </Card>}

                {sentResponse?.success && <Alert sx={{m: 2}} severity="success">
                    {sentResponse.message}
                </Alert>}
                {sentResponse?.success === false && <Alert severity="error">
                    {sentResponse.message}
                </Alert>}
            </Box>
        </>
    );
};

export default Home;
